export const ROLE_TYPE = {
  ADMIN: 'admin',
  DEFAULT: 'admin',
  DEVELOPER: 'developer',
}

export const CANCEL_REQUEST_MESSAGE = 'cancel request'

export const DEFAULT_PAGE_SIZE = 100;

export const STATUS_LIST = [
  {
    id: 1,
    name: "Tạo mới"
  },
  {
    id: 2,
    name: "Đang lấy hàng"
  },
  {
    id: 3,
    name: "Đã lấy hàng"
  },
  {
    id: 4,
    name: "Đang giao hàng"
  },
  {
    id: 5,
    name: "Giao hàng thành công"
  },
  {
    id: 6,
    name: "Hoãn giao hàng"
  },
  {
    id: 15,
    name: "Đang hoàn trả"
  },
  {
    id: 7,
    name: "Hủy giao hàng"
  },
  {
    id: 8,
    name: "Hoàn trả"
  },
  {
    id: 9,
    name: "Hoàn thành nội bộ"
  },
  {
    id: 10,
    name: "Đã đối soát"
  },
  {
    id: 11,
    name: "Chờ duyệt hoàn"
  },
  {
    id: 12,
    name: "Giao hàng 1 phần thành công"
  },
  {
    id: 13,
    name: "Hoàn trả thành công"
  },
  {
    id: 14,
    name: "Lưu kho"
  }
];

export const STATUS_API_LIST = [
  {
    id: 400,
    name: "API failed"
  },
  {
    id: 103,
    name: "Shipment Label Created"
  }
];

export const ORDER_TYPE = [
  'Giao hàng',
  'Đổi hàng',
  'Bình thường'
];

export const BILL_TYPE = [
  'Thu',
  'Chi',
  'Đối soát'
];

export const REASON = [
  'Không liên lạc được/Đến nơi không gặp',
  'Khách hẹn lại',
  'Sai địa chỉ/sđt',
  'Khách từ chối',
  'Giao hàng 1 phần'
];

export const FORMAT_DATE = 'DD/MM/YYYY HH:mm:ss';


export const INTERNATIONAL_STATUES = [
  {
    id: 1,
    name: "Shipment Created"
  },
  {
    id: 2,
    name: "Arived HUB"
  },
  {
    id: 3,
    name: "Complete customs formalities"
  },
  {
    id: 4,
    name: "In transit"
  },
  {
    id: 5,
    name: "With delivery courier"
  },
  {
    id: 6,
    name: "Delivered"
  },
  {
    id: 15,
    name: "Delivery failed"
  },
  {
    id: 7,
    name: "Holding"
  },
  {
    id: 8,
    name: "Cancel"
  }
];

export const ECOMM_STATUS = [
  {
    id: 1,
    name: "Created"
  },
  {
    id: 8,
    name: "Hanoinhanh Awaiting"
  },
  {
    id: 7,
    name: "Arrived Hanoinhanh"
  },
  {
    id: 2,
    name: "In transit"
  },
  {
    id: 6,
    name: "Delivered"
  },
  {
    id: 4,
    name: "Canceled"
  }
];

export const HPW_SERVICE = [
  {
    id: 'HPW Parcel',
    name: "HPW Parcel"
  },
  {
    id: 'HPW Oversize',
    name: "HPW Oversize"
  }
];

// export const HPW_SERVICE = [
//   {
//     id: 'HPW_USPS_JFK',
//     name: "HPW_USPS_JFK"
//   },
//   {
//     id: 'HPW_USPS_ORD',
//     name: "HPW_USPS_ORD"
//   },
//   {
//     id: 'HPW_USPS_LAX',
//     name: "HPW_USPS_LAX"
//   },
//   {
//     id: 'HPW_USPS_PS07',
//     name: "HPW_USPS_PS07"
//   },
//   {
//     id: 'HPW_PB_ORD',
//     name: "HPW_PB_ORD"
//   },
//   {
//     id: 'HPW_PB_LAX',
//     name: "HPW_PB_LAX"
//   },
//   {
//     id: 'HPW_PB_JFK',
//     name: "HPW_PB_JFK"
//   },
//   {
//     id: 'HPW_USPS_JFK_P',
//     name: "HPW_USPS_JFK_P"
//   },
//   {
//     id: 'HPW_USPS_ORD_P',
//     name: "HPW_USPS_ORD_P"
//   },
//   {
//     id: 'HPW_USPS_LAX_P',
//     name: "HPW_USPS_LAX_P"
//   },
//   {
//     id: 'HPW_USPS_GA_ORD_P',
//     name: "HPW_USPS_GA_ORD_P"
//   },
//   {
//     id: 'HPW_USPS_GA_JFK_P',
//     name: "HPW_USPS_GA_JFK_P"
//   },
//   {
//     id: 'HPW_USPS_GA_LAX_P',
//     name: "HPW_USPS_GA_LAX_P"
//   },
// ];

export const TYPE_CUSTOMER = [
  {
    id: "all",
    name: "All"
  },
  {
    id: "domestic",
    name: "Nội địa"
  },
  {
    id: "international",
    name: "Quốc tế"
  },
];

export const EPACKET_SERVICE = {
  1: 'Epacket US',
  2: 'Epacket WW',
  3: 'Epacket US Express',
  4: 'Epacket Zone 9',
  5: 'Epacket Big Size',
  6: 'Epacket Tiktok',
  7: 'Other'
}

export const EPACKET_SERVICE_LIST = [
  {id: 1, name: 'Epacket US'},
  {id: 2, name: 'Epacket WW'},
  {id: 3, name: 'Epacket US Express'},
  {id: 4, name: 'Epacket Zone 9'},
  {id: 5, name: 'Epacket Big Size'},
  {id: 6, name: 'Epacket Tiktok'},
  {id: 7, name: 'Other'},
]

export const EPACKET_STATUS = {
  1: 'Created',
  2: 'In transit',
  // 3: 'Arrived USPS',
  4: 'Canceled',
  5: 'Cleared debit',
  6: 'Delivered',
  7: 'Arrived Hanoinhanh',
  8: 'Hanoinhanh Awaiting'
}